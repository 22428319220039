<template>
  <div class="sigup-progress">
    <div class="w-100 image-signup">
      <b-img fluid :src="logoImg" alt="Login" class="d-block m-auto" />
    </div>
    <b-card-text class="mb-2 signup">
      <h1 class="text-center upper h1">{{ $t("g.signup") }}</h1>
      <p class="text-center">{{ $t("g.please_enter_your_details") }}</p>
    </b-card-text>

    <div class="box-circle mt-5 mb-3">
      <div class="circles d-flex justify-content-between align-items-center">
        <div class="circle d-flex justify-content-center align-items-center">
          <img :src="img1" alt="" :style="{'border-color': state === 2 || state === 3 ? '#135991' : '#5E5E5E'}" />
        </div>
        <hr
          :style="{
            'border-color':
              state === 2 || state === 3 ? '$primary-color' : '#5E5E5E',
          }"
        />
        <div
          class="circle d-flex justify-content-center align-items-center"
          :style="{
            'border-color':
              state === 2 || state === 3 ? '$primary-color' : '#5E5E5E',
          }"
        >
          <img :src="img2" alt="" />
        </div>
        <hr
          :style="{
            'border-color': state === 3 ? '$primary-color' : '#5E5E5E',
          }"
        />
        <div
          class="circle d-flex justify-content-center align-items-center"
          :style="{
            'border-color': state === 3 ? '$primary-color' : '#5E5E5E',
          }"
        >
          <img :src="img3" alt="" />
        </div>
      </div>
      <div
        class="text-circle d-flex justify-content-between align-items-center mx-auto"
      >
        <span
          :style="{
            color:
              state === 1 || state === 2 || state === 3
                ? '$primary-color'
                : '#5E5E5E',
          }"
          >{{ $t("g.personal_info") }}</span
        >
        <span
          :style="{
            color: state === 2 || state === 3 ? '$primary-color' : '#5E5E5E',
          }"
          >{{ $t("g.edu_info") }}</span
        >
        <span :style="{ color: state === 3 ? '$primary-color' : '#5E5E5E' }">{{
          $t("g.career_info")
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { BCardText, BImg } from "bootstrap-vue";
export default {
  components: {
    BCardText,
    BImg,
  },
  props: ["state"],
  data() {
    return {
      logoImg: require(`@/assets/images/futuer-icons/logo.svg`),
      img1: require("@/assets/images/signup/dot.png"),
      img2: require("@/assets/images/signup/empty.png"),
      img3: require("@/assets/images/signup/empty.png"),
    };
  },
  created() {
    this.state = 1;
  },
  watch: {
    state(newVal, oldVal) {
      if (this.state === 2) {
        this.img1 = require("@/assets/images/signup/Stepper.png");
        this.img2 = require("@/assets/images/signup/dot.png");
      }
      if (this.state === 3) {
        this.img2 = require("@/assets/images/signup/Stepper.png");
        this.img3 = require("@/assets/images/signup/dot.png");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./SignupProgressComponent.scss";
</style>
