<template>
  <div class="signup-info" v-if="state === 1">
    <b-row>
      <b-col md="6">
        <b-form-group label-for="First-name" class="control-input">
          <validation-provider
            #default="{ errors }"
            name="First Name"
            rules="required"
          >
            <b-form-input
              id="First-name"
              v-model="form_data.first_name"
              :state="errors.length > 0 ? false : null"
              name="First-name"
              class="edu-years"
              :placeholder="$t('g.firstName')"
            />
            <small class="text-danger">{{
              errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
            }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group class="control-input">
          <validation-provider
            #default="{ errors }"
            name="Last Name"
            rules="required"
          >
            <b-form-input
              v-model="form_data.last_name"
              :state="errors.length > 0 ? false : null"
              name="Last name"
              :placeholder="$t('g.lastName')"
              class="edu-years"
            />
            <small class="text-danger">{{
              errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
            }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group class="control-input">
          <validation-provider
            #default="{ errors }"
            name="email"
            rules="required"
          >
            <b-form-input
              type="email"
              v-model="form_data.email"
              :state="errors.length > 0 ? false : null"
              name="email"
              :placeholder="$t('g.email')"
              class="edu-years"
            />
            <small class="text-danger">{{
              errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
            }}</small>
          </validation-provider>
        </b-form-group></b-col
      >
      <b-col md="6">
        <b-form-group
          label-for="login-password"
          class="control-input pass-input"
        >
          <validation-provider
            #default="{ errors }"
            name="Password"
            rules="required"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="login-password"
                v-model="form_data.password"
                :state="errors.length > 0 ? false : null"
                class="form-control-merge edu-years"
                :type="passwordFieldType"
                name="login-password"
                :placeholder="$t('g.password')"
              />

              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  :icon="passwordToggleIcon"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{
              errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
            }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col md="6">
        <b-form-group
          label-for="login-password"
          class="control-input pass-input"
        >
          <validation-provider
            #default="{ errors }"
            name="Confirm Password"
            rules="required|confirmed:Password"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="confirm-password"
                v-model="form_data.password_confirmation"
                :state="errors.length > 0 ? false : null"
                class="form-control-merge edu-years"
                :type="confirmPasswordFieldType"
                name="login-password"
                :placeholder="$t('g.confirmPassword')"
              />

              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  :icon="confirmPasswordToggleIcon"
                  @click="toggleConfirmPasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{
              errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
            }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col md="6">
        <b-form-group class="control-input">
          <validation-provider
            #default="{ errors }"
            name="Nationality"
            rules="required"
          >
            <b-form-select
              v-model="form_data.nationality_id"
              :options="countries"
              :state="errors.length > 0 ? false : null"
              text-field="name"
              value-field="id"
              id="nationality"
              name="nationality"
              :placeholder="$t('g.nationality')"
              class="edu-years"
            />
            <label v-if="!form_data.nationality_id" class="label">{{
              $t("g.nationality")
            }}</label>
            <small class="text-danger">{{
              errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
            }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col md="6">
        <b-form-group class="control-input">
          <validation-provider
            #default="{ errors }"
            name="Country"
            rules="required"
          >
            <b-form-select
              type="text"
              v-model="form_data.country_id"
              :options="countries"
              :state="errors.length > 0 ? false : null"
              text-field="name"
              value-field="id"
              id="country"
              name="country"
              :placeholder="$t('g.country')"
              class="edu-years"
            />
            <label v-if="!form_data.country_id" class="label">{{
              $t("g.country")
            }}</label>
            <small class="text-danger">{{
              errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
            }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group class="control-input phone-input">
          <b-input-group>
            <template #prepend>
              <vue-country-code
                ref="vcc"
                @onSelect="onSelect"
                :preferredCountries="['sa', 'eg', 'us']"
                :enabledCountryCode="true"
              >
              </vue-country-code>
            </template>
            <validation-provider
              #default="{ errors }"
              name="phone"
              rules="required|min:9|max:11"
            >
              <b-form-input
                type="number"
                v-model="form_data.phone"
                :state="errors.length > 0 ? false : null"
                name="phone"
                :placeholder="$t('g.phone')"
                class="edu-years"
              />
              <small class="text-danger">{{
                errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
              }}</small>
            </validation-provider>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BInputGroupAppend,
  BFormGroup,
  BInputGroup,
  BFormSelect,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required, email, max, min, confirmed } from "@validations";
import { ValidationProvider } from "vee-validate";
import {
  togglePasswordVisibility,
  toggleConfirmPasswordVisibility,
} from "@core/mixins/ui/forms";
export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    ValidationProvider,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BInputGroupAppend,
  },
  props: ["form_data", "state"],
  mixins: [togglePasswordVisibility, toggleConfirmPasswordVisibility],
  data() {
    return {
      required,
      email,
      max,
      min,
      confirmed,
      countries: null,
    };
  },
  created() {
    this.getCountries();
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    confirmPasswordToggleIcon() {
      return this.confirmPasswordFieldType === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
  },
  methods: {
    getCountries() {
      this.$http
        .get(`web/countries`)
        .then((res) => {
          this.countries = res.data.data;
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("g.send.errorTitle"),
              text: err.response.data.message,
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
          console.log(err);
        });
    },
    onSelect({ name, iso2, dialCode }) {
      this.form_data.country_code = dialCode;
    },
    selectCountry(countryCode, key) {
      this.$refs.vcc.activeCountry.iso2 = countryCode;
      this.$refs.vcc.activeCountry.dialCode = key;
      this.form_data.country_code = countryCode;
    },
  },
  watch: {
    "form_data.country_id": {
      deep: true,
      handler() {
        this.countries.map((item) => {
          if (item.id === this.form_data.country_id) {
            this.selectCountry(item.flag, item.code);
          }
        });
      },
    },
  },
};
</script>

<style lang="scss">
@import "./SignupInfoComponent.scss";
</style>
