<template>
  <div class="signup-bg d-flex justify-content-center">
    <overlay-component :isLoading="overlayLoading" />
    <div class="circle-orange"></div>
    <b-row class="d-flex align-items-center px-2 p-lg-5 form-box">
      <b-col md="12" class="px-xl-2 mx-auto">
        <signup-progress-component :state="state"></signup-progress-component>

        <validation-observer ref="signupValidation">
          <b-form
            class="auth-signup-form mt-2 mx-auto box-form"
            @submit.prevent
          >
            <signup-info-component
              :form_data="form_data"
              :state="state"
            ></signup-info-component>

            <signup-edu-level-component
              :form_data="form_data"
              :state="state"
            ></signup-edu-level-component>

            <signup-career-info-component
              :form_data="form_data"
              :state="state"
            ></signup-career-info-component>

            <b-button
              type="submit"
              variant="primary"
              block
              class="btn-signup mx-auto"
              @click="validationForm"
            >
              {{ state === 3 ? $t("g.signup") : $t("g.Next") }}
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center my-3">
          <b-link :to="{ name: 'Login' }">
            <span class="btn-login text-center">{{
              $t("g.back_to_login")
            }}</span>
          </b-link>
        </b-card-text>
      </b-col>
    </b-row>
    <div class="circle-mix"></div>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import {
  BRow,
  BCol,
  BLink,
  BCardText,
  BCardTitle,
  BForm,
  BButton,
} from "bootstrap-vue";
import OverlayComponent from "@/components/shared/OverlayComponent";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import SignupProgressComponent from "@/components/signup/Progress/SignupProgressComponent.vue";
import SignupEduLevelComponent from "@/components/signup/eduLevel/SignupEduLevelComponent.vue";
import SignupCareerInfoComponent from "@/components/signup/careerInfo/SignupCareerInfoComponent.vue";
import SignupInfoComponent from "@/components/signup/Info/SignupInfoComponent.vue";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BCardText,
    BCardTitle,
    BForm,
    BButton,
    OverlayComponent,
    ValidationObserver,
    SignupProgressComponent,
    SignupEduLevelComponent,
    SignupCareerInfoComponent,
    SignupInfoComponent,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      form_data: {
        first_name: null,
        last_name: null,
        email: null,
        password: null,
        password_confirmation: null,
        nationality_id: null,
        phone: null,
        country_id: null,
        country_code: null,
        edu_years: null,
        edu_level_id: null,
        experience: null,
        subjects: null,
        cv: null,
      },
      overlayLoading: false,
      is_second: false,
      state: 1,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    validationForm() {
      this.$refs.signupValidation.validate().then((success) => {
        if (success) {
          if (this.state === 3) {
            let formData = new FormData();
            for (const key in this.form_data) {
              if (this.form_data[key] !== null) {
                if (key === "edu_years") {
                  for (const i in this.form_data[key]) {
                    formData.append(
                      `edu_years[${i}]`,
                      this.form_data[key][i].id
                    );
                  }
                } else if (key === "subjects") {
                  for (const i in this.form_data[key]) {
                    formData.append(`subjects[${i}]`, this.form_data[key][i]);
                  }
                } else {
                  formData.append(key, this.form_data[key]);
                }
              }
            }

            this.overlayLoading = true;

            this.$http
              .post(`auth/teacher/register`, formData)
              .then((res) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: this.$t("g.send.successTitle"),
                    icon: "EditIcon",
                    variant: "success",
                    text: res.data.data.message,
                  },
                });
                setTimeout(() => {
                  this.$router.push({ name: "Login" });
                }, 500);
                this.overlayLoading = false;
              })
              .catch((err) => {
                for (const key in err.response.data.errors) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: this.$t("g.send.errorTitle"),
                      text: err.response.data.errors[key][0],
                      icon: "AlertCircleIcon",
                      variant: "danger",
                    },
                  });
                }
                this.overlayLoading = false;
              });
          } else {
            this.state = this.state + 1;
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
@import "./index.scss";
</style>
