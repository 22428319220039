<template>
  <div class="edu-year-box" v-if="state === 2">
    <b-row>
      <b-col md="6">
        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Educational level"
            rules="required"
          >
            <b-form-select
              v-model="form_data.edu_level_id"
              :options="EDULevel"
              :state="errors.length > 0 ? false : null"
              text-field="name"
              value-field="id"
              id="Educational-level"
              name="Educational level"
              :placeholder="$t('g.educational_level')"
              class="edu-years"
            />
            <small class="text-danger">{{
              errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
            }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group label-for="experince-year" class="control-input">
          <validation-provider
            #default="{ errors }"
            name="experince year"
            rules="required"
          >
            <b-form-input
              type="number"
              id="experince-year"
              v-model="form_data.experience"
              :state="errors.length > 0 ? false : null"
              name="experince year"
              class="edu-years"
              placeholder=""
            />
            <label class="label">{{ $t("g.experince-year") }}</label>
            <small class="text-danger">{{
              errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
            }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group label-for="experince-year" class="control-input">
          <validation-provider #default="{ errors }" name="cv" rules="required">
            <div class="image-input-area">
              <b-form-file
                class="image-input"
                v-model="form_data.cv"
                :state="errors.length > 0 ? false : null"
              ></b-form-file>
              <p class="image-text mb-0">
                {{ $t("g.upload_your_cv") }}
              </p>
              <div class="image-icon">
                <img :src="uploadIcon" alt="Cover" />
              </div>
            </div>
            <small class="text-danger">{{
              errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
            }}</small>
          </validation-provider>
          <div class="mt-1">
            {{
              `${$t("g.selected_file")} ${
                form_data.cv ? form_data.cv.name : ""
              }`
            }}
          </div>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormSelect,
  BRow,
  BCol,
  BFormInput,
  BFormFile,
} from "bootstrap-vue";
import { ValidationProvider } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BFormGroup,
    ValidationProvider,
    BFormSelect,
    BRow,
    BCol,
    BFormInput,
    BFormFile,
  },
  props: ["form_data", "state"],
  data() {
    return {
      EDULevel: [
        {
          name: this.$t("g.educational_level"),
          value: null,
          disabled: true,
          seleceted: true,
        },
      ],
      required,
      uploadIcon: require("@/assets/images/icons/upload.svg"),
    };
  },
  created() {
    this.getEDULevel();
  },
  methods: {
    getEDULevel() {
      this.$http
        .get(`web/edu-levels`)
        .then((res) => {
          res.data.data.map((item) => {
            item.is_available && this.EDULevel.push(item);
          });
        })
        .catch((err) => {
          for (const key in err.response.data.errors) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t("g.send.errorTitle"),
                text: err.response.data.errors[key][0],
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          }
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./SignupEduLevelComponent.scss";
</style>
