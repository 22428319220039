var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sigup-progress"},[_c('div',{staticClass:"w-100 image-signup"},[_c('b-img',{staticClass:"d-block m-auto",attrs:{"fluid":"","src":_vm.logoImg,"alt":"Login"}})],1),_c('b-card-text',{staticClass:"mb-2 signup"},[_c('h1',{staticClass:"text-center upper h1"},[_vm._v(_vm._s(_vm.$t("g.signup")))]),_c('p',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t("g.please_enter_your_details")))])]),_c('div',{staticClass:"box-circle mt-5 mb-3"},[_c('div',{staticClass:"circles d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"circle d-flex justify-content-center align-items-center"},[_c('img',{style:({'border-color': _vm.state === 2 || _vm.state === 3 ? '#135991' : '#5E5E5E'}),attrs:{"src":_vm.img1,"alt":""}})]),_c('hr',{style:({
          'border-color':
            _vm.state === 2 || _vm.state === 3 ? '$primary-color' : '#5E5E5E',
        })}),_c('div',{staticClass:"circle d-flex justify-content-center align-items-center",style:({
          'border-color':
            _vm.state === 2 || _vm.state === 3 ? '$primary-color' : '#5E5E5E',
        })},[_c('img',{attrs:{"src":_vm.img2,"alt":""}})]),_c('hr',{style:({
          'border-color': _vm.state === 3 ? '$primary-color' : '#5E5E5E',
        })}),_c('div',{staticClass:"circle d-flex justify-content-center align-items-center",style:({
          'border-color': _vm.state === 3 ? '$primary-color' : '#5E5E5E',
        })},[_c('img',{attrs:{"src":_vm.img3,"alt":""}})])]),_c('div',{staticClass:"text-circle d-flex justify-content-between align-items-center mx-auto"},[_c('span',{style:({
          color:
            _vm.state === 1 || _vm.state === 2 || _vm.state === 3
              ? '$primary-color'
              : '#5E5E5E',
        })},[_vm._v(_vm._s(_vm.$t("g.personal_info")))]),_c('span',{style:({
          color: _vm.state === 2 || _vm.state === 3 ? '$primary-color' : '#5E5E5E',
        })},[_vm._v(_vm._s(_vm.$t("g.edu_info")))]),_c('span',{style:({ color: _vm.state === 3 ? '$primary-color' : '#5E5E5E' })},[_vm._v(_vm._s(_vm.$t("g.career_info")))])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }