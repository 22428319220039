<template>
  <div class="career-info" v-if="state === 3">
    <b-row>
      <b-col lg="6">
        <b-form-group label-for="subjuct">
          <validation-provider
            #default="{ errors }"
            name="subjuct"
            rules="required"
          >
            <v-select
              v-model="form_data.subjects"
              :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
              :options="subjects"
              :state="errors.length > 0 ? false : null"
              multiple
              :reduce="(subject) => subject.id"
              class="edu-years"
              :placeholder="$t('g.subjuct')"
              label="name"
            />

            <small class="text-danger">{{
              errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
            }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group label-for="educational_year">
          <validation-provider
            #default="{ errors }"
            name="Educational year"
            rules="required"
          >
            <v-select
              v-model="form_data.edu_years"
              :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
              :options="EDUYears"
              :state="errors.length > 0 ? false : null"
              multiple
              class="edu-years"
              :placeholder="$t('g.educational_year')"
              label="name"
            />

            <small class="text-danger">{{
              errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
            }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BFormGroup, BFormSelect, BRow, BCol } from "bootstrap-vue";
import { ValidationProvider } from "vee-validate";
import { required } from "@validations";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BFormGroup,
    ValidationProvider,
    BFormSelect,
    BRow,
    BCol,
    vSelect,
  },
  props: ["form_data", "state"],
  data() {
    return {
      required,
    };
  },
  created() {
    this.getEDUYears();

    this.getSubjects();
  },
  methods: {
    getSubjects() {
      this.$http
        .get(`web/subjects`)
        .then((res) => {
          this.subjects = res.data.data;
        })
        .catch((err) => {
          for (const key in err.response.data.errors) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t("g.send.errorTitle"),
                text: err.response.data.errors[key][0],
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          }
          console.log("catch error:");
        });
    },
    getEDUYears() {
      this.$http
        .get(`web/edu-years`)
        .then((res) => {
          this.EDUYears = res.data.data;
        })
        .catch((err) => {
          for (const key in err.response.data.errors) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t("g.send.errorTitle"),
                text: err.response.data.errors[key][0],
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          }
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss">
@import "./SignupCareerInfoComponent.scss";
</style>
